import React from "react";
import { Link } from "react-router-dom";
import "./not-found.scss";
import Screen from "../../components/screen/screen";

export default function NotFound() {
  return (
    <Screen name="not-found">
      <h1>Page Not Found</h1>
      <Link to="/">Back to Home</Link>
    </Screen>
  );
}
